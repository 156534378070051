@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);
.loader {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.loader__dot {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.loader__dot:before {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0149db;
  position: absolute;
  left: 50%;
  top: -55px;
  margin-left: -10px;
  margin-top: -10px;
}


/*
@for $i from 0 to 11 {
      @keyframes dot-#{$i} {
        0% {
          transform: rotate(180deg + $i * (360deg / 11));
        }

        100% {
          transform: rotate(180deg + ($i + 1) * (5 * 360deg / 11) + $i * (360deg / 11));
        }
      }

      &:nth-child(#{$i + 1}) {
        transform: rotate($i * (360deg / 11));
        animation: dot-#{$i} 5s cubic-bezier(.3, .0, .7, 1) infinite;
      }
    }
*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
.modal-overlay_hidden {
  display: none;
}
.modal-overlay_show {
  display: flex;
}
.modal {
  width: 450px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}
.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.modal__header .title {
  margin-bottom: 0;
}
.modal__body {
  max-height: 300px;
  overflow-y: auto;
}
.modal__footer {
  margin-top: 30px;
  text-align: center;
}

.offers {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
}
.offers__search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.offers-search {
  display: flex;
}
.offers-search .field + .field {
  margin-top: 0;
  margin-left: 15px;
}
.form-offers__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-offers__header .title {
  margin-bottom: 0;
}
.form-offers__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-offers__controls .icon {
  margin-left: 15px;
}
.form-offers__subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.form-offers__subheader .title {
  margin-bottom: 0;
}
.form-offers__body_short .offer__logo {
  padding-top: 0;
  padding-bottom: 0;
}
.form-offers__body_short .offer__logo .field {
  display: none;
}
.form-offers__body_short .offer__info .data__item {
  margin: 0;
}
.form-offers__body_short .offer__info .data__item:nth-child(n+4) {
  display: none;
}
.form-offers__body_short .offer__info .data__item input {
  height: 32px;
  border: 1px solid #e7ecf2;
  border-radius: 6px;
}
.form-offers__body_short .offer__conditions {
  padding-top: 0;
  padding-bottom: 0;
}
.offer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
}
.offer:hover .offer__options {
  display: flex;
}
.offer + .offer {
  margin-top: 15px;
}
.offer_details > .offer__additional {
  display: block;
}
.offer_hidden {
  background-color: #e7ecf2;
}
.offer .title {
  margin-top: 10px;
  margin-bottom: 10px;
}
.offer__position {
  position: absolute;
  top: 50%;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}
.offer__general {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
@media (max-width: 620px) {
  .offer__general {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .offer__general .offer__logo {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }
  .offer__general .offer__info {
    width: 100%;
  }
}
.offer__options {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #e7ecf2;
  cursor: pointer;
}
.offer__additional {
  display: none;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5;
}
.offer__logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-shrink: 0;
  width: 150px;
  margin-right: 15px;
  padding: 7px 15px 7px 0;
  border-right: 1px solid #e5e5e5;
}
.offer__company {
  text-align: center;
}
.offer__company img {
  width: 100%;
  max-width: 150px;
}
.offer__company .field {
  margin-bottom: 10px;
}
.offer__list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.offer__info {
  flex-grow: 2;
}
.offer__info .data__item {
  width: 33.3333%;
}
@media (max-width: 400px) {
  .offer__info .data__item {
    width: 50%;
  }
}
.offer__conditions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  margin-left: 15px;
  padding: 7px 0 7px 15px;
  border-left: 1px solid #e5e5e5;
}
@media (max-width: 620px) {
  .offer__conditions {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
    width: 100%;
  }
  .offer__conditions .offer__actions {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.offer__actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.offer__actions .icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 6px;
  border-radius: 50%;
  background-color: #e7ecf2;
  cursor: pointer;
}
@media (max-width: 620px) {
  .offer__actions .icon-wrap + .icon-wrap {
    margin-left: 10px;
  }
}

.apps {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
}
.apps__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.apps__header .title {
  margin-bottom: 0;
}
.apps__controls {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.apps__controls .btn + .btn {
  margin-left: 15px;
}
.form__controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.form__field-trackers {
  margin-top: 20px;
}
.form .subtitle {
  margin-bottom: 20px;
}

.partners {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
}
.partners__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.partners__header .title {
  margin-bottom: 0;
}
.partners__controls {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.partners__controls .btn + .btn {
  margin-left: 15px;
}
.form__controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 450px;
  height: calc(100vh - 75px);
  margin: 0 auto;
}
.auth .field {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 30px;
}
.auth .btn {
  width: 150px;
  margin: 60px auto 0;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
  .nav_open {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e7ecf2;
  }
}
.nav__link {
  display: inline-block;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  padding: 22px 20px;
}
.nav__link_active {
  border-color: #0149db;
}
.nav__link_hidden {
  display: none;
}
 
.header {
  position: relative;
  height: 60px;
  border-bottom: 1px solid #e7ecf2;
}
.header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header__nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 2;
}
.header__logo {
  width: auto;
  height: 32px;
  margin-right: 40px;
}
@media (min-width: 769px) {
  .header__controls .icon_layer {
    display: none;
  }
}
@media (max-width: 768px) {
  .header__controls .icon_logout {
    display: none;
  }
}

.layout__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 60px;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ol, ul {
  list-style: none;
}
input, textarea, button, select, a {
  outline: none;
}
html {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}
body {
  font-size: 14px;
  line-height: 1;
  font-family: 'Inter', sans-serif;
  color: #323232;
  background-color: #fff;
}
a {
  color: #323232;
  text-decoration: none;
}
b {
  font-weight: 700;
}
table {
  width: 100%;
}
table th {
  text-align: left;
}
table th, table td {
  padding: 10px;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
}
table .field_checkbox + .field_checkbox {
  margin-top: 7px !important;
}
.table_categories tbody td {
  padding: 5px;
}
.table_categories tbody label {
  display: none;
}
.table_categories tbody input {
  border-bottom: none;
}
.container {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
}
.col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.col + .col {
  margin-top: 10px;
}
.col input {
  margin-left: 5px;
}
.title {
  margin-bottom: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 21px;
  text-transform: uppercase;
}
.link {
  padding-bottom: 2px;
  color: #0149db;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #0149db;
  cursor: pointer;
}
.mark {
  color: #0149db;
}
.rating {
  color: #ffd700;
}
.list-top__item {
  font-size: 13px;
}
.list-top__item + .list-top__item {
  margin-top: 10px;
}
.data {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.data__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 25%;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-right: 5px;
}
@media (max-width: 450px) {
  .data__item {
    width: 100%;
  }
}
.data__item label {
  width: 100%;
}
.data__item .field + .field {
  margin-top: 0;
}

.fields {
  width: 100%;
}
.field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.field + .field {
  margin-top: 14px;
}
.field_checkbox {
  flex-direction: row !important;
  align-items: center;
}
.field_checkbox label, .field_checkbox input {
  margin: 0;
}
.field_checkbox label {
  margin-right: 10px;
}
.field_bordered {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.btn {
  display: inline-block;
  min-width: 155px;
  padding: 10px 20px 11px;
  border-radius: 20px;
  background-color: #0149db;
  border: none;
  outline: none;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.btn_outline {
  padding: 8px 20px 9px;
  background-color: transparent;
  color: #0149db;
  border: 2px solid #0149db;
}
.btn_info {
  background-color: #32bea6;
}
.btn + .btn {
  margin-left: 15px;
}
.btn-link {
  padding: 0 0 2px;
  color: #0149db;
  font-size: 14px;
  outline: none;
  border: none;
  border-bottom: 1px dashed #0149db;
  cursor: pointer;
  background: none;
}
.btn-link_test {
  color: #ffb200;
  border-color: #ffb200;
}
.btn-link_info {
  color: #32bea6;
  border-color: #32bea6;
}
.btn-link_remove {
  color: #a10e0e;
  border-color: #a10e0e;
}
.btn-group {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.btn-group__list {
  display: none;
  position: absolute;
  top: 39px;
  right: 0;
  width: 155px;
  background-color: #0149db;
  border-radius: 10px;
}
.btn-group__list_active {
  display: block;
}
.btn-group__list-item {
  padding: 10px 20px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}
.btn-group__list-item + .btn-group__list-item {
  border-top: 1px solid #fff;
}
.btn-group .btn {
  display: flex;
  min-width: auto;
}
.btn-group .btn:first-child {
  padding-right: 10px;
  border-radius: 20px 0 0 20px;
}
.btn-group .btn + .btn {
  margin-left: 0;
  padding-left: 10px;
  border-radius: 0 20px 20px 0;
}
.control {
  outline: none;
  border: none;
  width: 30px;
  height: 30px;
  background: url(/static/media/icon-down.0f76b979.svg) no-repeat;
  cursor: pointer;
}
.control_up {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.select {
  position: relative;
}
.select::before {
  content: '';
  position: absolute;
  top: 6px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: url(/static/media/icon-down.0f76b979.svg) no-repeat;
  background-size: contain;
  pointer-events: none;
}
.select select {
  width: 100%;
  min-width: 150px;
  height: 32px;
  padding: 6px 15px 7px;
  border: 1px solid #e7ecf2;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}
.input, input {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(50, 50, 50, 0.5);
}
.input_long {
  width: 50px;
}
.input_short {
  width: 30px;
}
.input_space {
  width: 20px;
  border: none;
}
.input_hidden {
  width: 1px;
  height: 1px;
  visibility: hidden;
}
label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(50, 50, 50, 0.5);
}
.label_custom {
  margin: 0 !important;
}
.textarea {
  width: 100%;
  height: 150px;
  min-height: 100px;
  padding: 6px 15px 7px;
  border: 1px solid #e7ecf2;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  resize: none;
}
.textarea_small {
  height: 75px;
  min-height: 75px;
}

.icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.icon + .icon {
  margin-left: 10px;
}
.icon_large {
  width: 20px;
  height: 20px;
}
.icon_down {
  background: url(/static/media/icon-down.0f76b979.svg) no-repeat;
  background-size: contain;
}
.icon_down-white {
  background: url(/static/media/icon-down-white.af6f1a03.svg) no-repeat;
  background-size: contain;
}
.icon_edit {
  background: url(/static/media/icon-edit.5aea0697.svg) no-repeat;
  background-size: contain;
}
.icon_remove {
  background: url(/static/media/icon-remove.1d5da1e5.svg) no-repeat;
  background-size: contain;
}
.icon_good {
  background: url(/static/media/icon-good.56540b5a.svg) no-repeat;
  background-size: contain;
}
.icon_bad {
  background: url(/static/media/icon-bad.2c02013c.svg) no-repeat;
  background-size: contain;
}
.icon_star {
  background: url(/static/media/icon-star.7dc6e5d5.svg) no-repeat;
  background-size: contain;
}
.icon_time {
  background: url(/static/media/icon-time.3a9e6220.svg) no-repeat;
  background-size: contain;
}
.icon_visibility {
  background: url(/static/media/icon-visibility.4d7c2c40.svg) no-repeat;
  background-size: contain;
}
.icon_settings {
  background: url(/static/media/icon-settings.b5ee8732.svg) no-repeat;
  background-size: contain;
}
.icon_layer {
  background: url(/static/media/icon-layer.ccc43857.svg) no-repeat;
  background-size: contain;
}
.icon_layer-large {
  background: url(/static/media/icon-layer-large.e71775c2.svg) no-repeat;
  background-size: contain;
}
.icon_logout {
  background: url(/static/media/icon-logout.ed22c2ca.svg) no-repeat;
  background-size: contain;
}
.icon_calendar {
  background: url(/static/media/icon-calendar.9584ce90.svg) no-repeat;
  background-size: contain;
}
.icon_filter {
  background: url(/static/media/icon-filter.c8a3fc4b.svg) no-repeat;
  background-size: contain;
}
.icon_filtration {
  background: url(/static/media/icon-filtration.c775629b.svg) no-repeat;
  background-size: contain;
}
.icon_options {
  background: url(/static/media/icon-more-options.642d6e05.svg) no-repeat;
  background-size: contain;
}



