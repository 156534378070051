html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ol, ul {
  list-style: none;
}
input, textarea, button, select, a {
  outline: none;
}
html {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}
body {
  font-size: 14px;
  line-height: 1;
  font-family: 'Inter', sans-serif;
  color: #323232;
  background-color: #fff;
}
a {
  color: #323232;
  text-decoration: none;
}
b {
  font-weight: 700;
}
table {
  width: 100%;
}
table th {
  text-align: left;
}
table th, table td {
  padding: 10px;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
}
table .field_checkbox + .field_checkbox {
  margin-top: 7px !important;
}
.table_categories tbody td {
  padding: 5px;
}
.table_categories tbody label {
  display: none;
}
.table_categories tbody input {
  border-bottom: none;
}
.container {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
}
.col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.col + .col {
  margin-top: 10px;
}
.col input {
  margin-left: 5px;
}
.title {
  margin-bottom: 15px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 21px;
  text-transform: uppercase;
}
.link {
  padding-bottom: 2px;
  color: #0149db;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #0149db;
  cursor: pointer;
}
.mark {
  color: #0149db;
}
.rating {
  color: #ffd700;
}
.list-top__item {
  font-size: 13px;
}
.list-top__item + .list-top__item {
  margin-top: 10px;
}
.data {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.data__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 25%;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-right: 5px;
}
@media (max-width: 450px) {
  .data__item {
    width: 100%;
  }
}
.data__item label {
  width: 100%;
}
.data__item .field + .field {
  margin-top: 0;
}
