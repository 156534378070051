.icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.icon + .icon {
  margin-left: 10px;
}
.icon_large {
  width: 20px;
  height: 20px;
}
.icon_down {
  background: url('./../images/icon-down.svg') no-repeat;
  background-size: contain;
}
.icon_down-white {
  background: url('./../images/icon-down-white.svg') no-repeat;
  background-size: contain;
}
.icon_edit {
  background: url('./../images/icon-edit.svg') no-repeat;
  background-size: contain;
}
.icon_remove {
  background: url('./../images/icon-remove.svg') no-repeat;
  background-size: contain;
}
.icon_good {
  background: url('./../images/icon-good.svg') no-repeat;
  background-size: contain;
}
.icon_bad {
  background: url('./../images/icon-bad.svg') no-repeat;
  background-size: contain;
}
.icon_star {
  background: url('./../images/icon-star.svg') no-repeat;
  background-size: contain;
}
.icon_time {
  background: url('./../images/icon-time.svg') no-repeat;
  background-size: contain;
}
.icon_visibility {
  background: url('./../images/icon-visibility.svg') no-repeat;
  background-size: contain;
}
.icon_settings {
  background: url('./../images/icon-settings.svg') no-repeat;
  background-size: contain;
}
.icon_layer {
  background: url('./../images/icon-layer.svg') no-repeat;
  background-size: contain;
}
.icon_layer-large {
  background: url('./../images/icon-layer-large.svg') no-repeat;
  background-size: contain;
}
.icon_logout {
  background: url('./../images/icon-logout.svg') no-repeat;
  background-size: contain;
}
.icon_calendar {
  background: url('./../images/icon-calendar.svg') no-repeat;
  background-size: contain;
}
.icon_filter {
  background: url('./../images/icon-filter.svg') no-repeat;
  background-size: contain;
}
.icon_filtration {
  background: url('./../images/icon-filtration.svg') no-repeat;
  background-size: contain;
}
.icon_options {
  background: url('./../images/icon-more-options.svg') no-repeat;
  background-size: contain;
}
