.fields {
  width: 100%;
}
.field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.field + .field {
  margin-top: 14px;
}
.field_checkbox {
  flex-direction: row !important;
  align-items: center;
}
.field_checkbox label, .field_checkbox input {
  margin: 0;
}
.field_checkbox label {
  margin-right: 10px;
}
.field_bordered {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.btn {
  display: inline-block;
  min-width: 155px;
  padding: 10px 20px 11px;
  border-radius: 20px;
  background-color: #0149db;
  border: none;
  outline: none;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.btn_outline {
  padding: 8px 20px 9px;
  background-color: transparent;
  color: #0149db;
  border: 2px solid #0149db;
}
.btn_info {
  background-color: #32bea6;
}
.btn + .btn {
  margin-left: 15px;
}
.btn-link {
  padding: 0 0 2px;
  color: #0149db;
  font-size: 14px;
  outline: none;
  border: none;
  border-bottom: 1px dashed #0149db;
  cursor: pointer;
  background: none;
}
.btn-link_test {
  color: #ffb200;
  border-color: #ffb200;
}
.btn-link_info {
  color: #32bea6;
  border-color: #32bea6;
}
.btn-link_remove {
  color: #a10e0e;
  border-color: #a10e0e;
}
.btn-group {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.btn-group__list {
  display: none;
  position: absolute;
  top: 39px;
  right: 0;
  width: 155px;
  background-color: #0149db;
  border-radius: 10px;
}
.btn-group__list_active {
  display: block;
}
.btn-group__list-item {
  padding: 10px 20px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}
.btn-group__list-item + .btn-group__list-item {
  border-top: 1px solid #fff;
}
.btn-group .btn {
  display: flex;
  min-width: auto;
}
.btn-group .btn:first-child {
  padding-right: 10px;
  border-radius: 20px 0 0 20px;
}
.btn-group .btn + .btn {
  margin-left: 0;
  padding-left: 10px;
  border-radius: 0 20px 20px 0;
}
.control {
  outline: none;
  border: none;
  width: 30px;
  height: 30px;
  background: url("../images/icon-down.svg") no-repeat;
  cursor: pointer;
}
.control_up {
  transform: rotate(180deg);
}
.select {
  position: relative;
}
.select::before {
  content: '';
  position: absolute;
  top: 6px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: url("../images/icon-down.svg") no-repeat;
  background-size: contain;
  pointer-events: none;
}
.select select {
  width: 100%;
  min-width: 150px;
  height: 32px;
  padding: 6px 15px 7px;
  border: 1px solid #e7ecf2;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
}
.input, input {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(50, 50, 50, 0.5);
}
.input_long {
  width: 50px;
}
.input_short {
  width: 30px;
}
.input_space {
  width: 20px;
  border: none;
}
.input_hidden {
  width: 1px;
  height: 1px;
  visibility: hidden;
}
label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(50, 50, 50, 0.5);
}
.label_custom {
  margin: 0 !important;
}
.textarea {
  width: 100%;
  height: 150px;
  min-height: 100px;
  padding: 6px 15px 7px;
  border: 1px solid #e7ecf2;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  resize: none;
}
.textarea_small {
  height: 75px;
  min-height: 75px;
}
